import React, { useState } from "react"
import styled from "styled-components"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

import Layout from "layout/Layout"

import CategoryOption from "components/CategoryOption/CategoryOption"

import Elements from "images/elements.svg"

const StyledWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 500px;
  background-color: ${({ theme }) => theme.colors.services.servicesBackground};
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url(${Elements});
  background-size: cover;
  background-position: center;

  @media (min-width: 700px) {
    min-height: 800px;
  }
`

const StyledTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.m};
  color: ${({ theme }) => theme.colors.servicesOptions.title};
  text-transform: uppercase;

  @media (max-width: 1000px) {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
`

const StyledOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: auto;
`

function Services() {
  const intl = useIntl()

  const [options] = useState([
    {
      // name: intl.formatMessage({ id: "services-options.options.0.name" }),
      path: "/services/fertilizers/",
    },
    {
      // name: intl.formatMessage({ id: "services-options.options.1.name" }),
      path: "/services/lines/",
    },
  ])

  return (
    <Layout>
      <>
        <StyledWrapper>
          <StyledTitle>
            {/* <FormattedMessage id="services-options.title" /> */}
          </StyledTitle>
          <StyledOptionsContainer>
            {options.map(({ name, path }) => (
              <CategoryOption name={name} key={name} path={path} />
            ))}
          </StyledOptionsContainer>
        </StyledWrapper>
      </>
    </Layout>
  )
}

export default Services
