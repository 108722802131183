import React from "react"
import styled from "styled-components"
import { Link } from "gatsby-plugin-intl"
import PropTypes from "prop-types"

const StyledButton = styled.button`
  width: 500px;
  max-width: 75vw;
  height: 60px;
  background-color: ${({ theme }) =>
    theme.colors.servicesOptions.optionBackground};
  border: ${({ theme }) =>
    `1px solid ${theme.colors.servicesOptions.optionBorder}`};
  transition: all 0.25s ease;
  cursor: pointer;
  margin: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.servicesOptions.optionText};
  text-align: center;
  border-radius: 10px;
  text-transform: uppercase;

  @media (max-width: 1000px) {
    font-size: ${({ theme }) => theme.fontSize.xxs};
    height: 50px;
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.servicesOptions.optionBackgroundHover};
  }
`

const CategoryOption = ({ name, path }) => (
  <Link to={path} style={{ textDecoration: "none" }}>
    <StyledButton>{name}</StyledButton>
  </Link>
)

CategoryOption.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

export default CategoryOption
